@import '../../style/_global.scss';

#single-floor-plan {
  height: calc(100% - 154px);

  .floor-plan-wrap {
    width: 78%;
  }
  
  .side-menu-wrap {
    width: 22%;
  }

  .elevations {
    max-width: 180px;

    @media (min-height: 1060px) {
      max-width: 284px;
    }
  }
  
  .elevation {
    position: relative;

    .caption {
      position: absolute;
      bottom: 0.35rem;
      left: 0.5rem;
      @include sofiaSemi;
      font-size: 0.85rem;
      line-height: 1;
      color: #fff;
      text-shadow: 0 0 3px rgba(0,0,0,0.5);

      @media (min-height: 1060px) {
        font-size: 1rem;
      }
    }
  }
  
  .title {
    @include sofiaBold;
    font-size: 3.5rem;
    line-height: 1;
    color: $blue;

    @include bp(1900) {
      font-size: 5rem;
    }

    span {
      @include sofiaSemi;
      font-size: 1.35em;
      line-height: 1.2;
      color: $medBlue;
    }
  }

  .details {
    margin-top: -1rem;
    font-size: 1.15rem;
    line-height: 1.7;

    @include bp(1900) {
      font-size: 1.5rem;
    }
  }

  .floorplan {
    width: auto;
    max-width: 50%;
    height: auto;
    max-height: 100%;
  }
}