@import '../../style/global';

#side-menu {
  background: #f4f4f4;
  // width: 26.82%;
  height: 100%;
  padding: 1rem 2rem;

  @media (max-height: 1060px) {
    padding: 0.5rem 1rem;
  }

  .personalize {
    max-width: 315px;
    margin-bottom: 1rem;

    @media (max-height: 1060px) {
      margin-bottom: 0.5rem;
    }
  }

  .options-title {
    font-size: 1.5rem;
    letter-spacing: 2px;
  }

  ul {
    list-style-type: none;
    margin: 0 0 0.25rem;
    padding: 0;
    font-size: 1.25rem;

    @media (max-height: 1060px) {
      font-size: 1rem;
    }
  }

  li {
    margin: 0.5rem 0;
    line-height: 1;

    @media (min-height: 1060px) {
      margin: 1.75rem 0;
      line-height: unset;
    }
  }

  .btn {

    @media (max-height: 1060px) {
      padding-bottom: 0.5rem;
    }
  }

  a {
    @include sofiaSemi;
    font-size: 1.25rem;

    @include bp(1200) {
      font-size: 1.5rem;
    }

    &.active {
      @include sofiaBold;
      color: $text;
    }
  }
}
