@import "../../style/_global.scss";

#blossom-rock {
  .internal-wrap {
    position: relative;
    display: flex;
    padding: 0;

    .corner-graphic {
      position: absolute;
      top: 0;
      right: 0;
      width: 764px;
      height: 280px;
    }

    .left-wrap {
      width: 35%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .right-wrap {
      width: 65%;
      height: 100%;
      background-color: #f2f2f2;

      .video-wrap {
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .blossom-big-slider {
          position: relative;
          width: 1034px;
          height: calc(1034px * 1080 / 1920);
          margin: 0 auto;
          opacity: 1;

          &.show {
            opacity: 1;
            transition: opacity;
          }

          &.slick-initialized .slick-slide {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
          }

          .slick-list {
            height: 100%;
            padding: 0 !important;
          }

          .slick-track {
            // width: 100% !important;
            height: 100%;
            display: flex !important;
          }

          .slick-slide {
            width: 100% !important;
            height: 100%;

            > div {
              height: 100%;
            }
          }

          .slide {
            position: relative;
            width: 100% !important;
            height: 100%;
            float: none !important;
            padding: 0 !important;

            img {
              width: auto;
              height: 100%;
            }

            .caption {
              position: absolute;
              bottom: 0.5rem !important;
              left: 1rem !important;
              color: #ffffff;
              @include sofiaSemi;
              font-size: 2.25 rem;
              text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
            }
          }
        }

        .video {
          position: relative;
          width: 1034px;
          height: calc(1034px * 1080 / 1920);

          video {
            width: 100%;
            height: 100%;
          }

          .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 158px;
            height: 158px;
            background: none;
            border: none;
            padding: 0;
            margin: 0;

            svg {
              width: 100%;
              height: 100%;
              fill: #ffffff;
              filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
            }
          }
        }
      }

      .gallery-wrap {
        height: 30%;
        margin-top: 2.5rem;

        .video {
          position: relative;

          img {
            width: auto;
            height: 100%;
          }

          .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 58px;
            height: 58px;
            background: none;
            border: none;
            padding: 0;
            margin: 0;

            svg {
              width: 100%;
              height: 100%;
              fill: #ffffff;
              filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
            }
          }
        }

        .blossom-slider {
          position: relative;
          width: 930px;
          height: 165.5px;
          margin: 0 auto;
          opacity: 1;

          &.show {
            opacity: 1;
            transition: opacity;
          }

          &.slick-initialized .slick-slide {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
          }

          .slick-list {
            height: 100%;
            padding: 0 !important;
          }

          .slick-track {
            width: 100% !important;
            height: 100%;
            display: flex !important;
          }

          .slick-slide {
            width: 100% !important;
            height: 100%;

            > div {
              height: 100%;
            }
          }

          .slick-current {
            .slide {
              // border: 2px solid #f39a33;
            }
          }

          .slide {
            width: auto !important;
            height: 100%;
            float: none !important;
            // border: 2px solid transparent;
            margin: 0 0.5rem;
          }

          .arrow {
            position: absolute;
            top: 50%;
            right: -50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: none;
            width: 50px;
            height: 50px;
            border: 0;
            z-index: 2;
            transform: translateY(-50%);

            &.prev {
              right: auto;
              left: -50px;
            }

            &.slick-disabled {
              opacity: 0;
              cursor: default;
            }

            svg {
              display: block;
              width: 100%;
              height: 100%;
              filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
            }
          }
        }
      }
    }
  }

  // .amenities-btn {
  //   position: absolute;
  //   bottom: 2rem;
  //   left: 2rem;

  //   @include bp(1900) {
  //     bottom: 7rem;
  //     left: 5rem;
  //   }

  //   @media (max-height: 1000px) {
  //     left: 3.5rem;
  //   }
  // }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
