@import '../../style/_global.scss';

#home {
  position: relative;

  .shapes {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .lockup {
    width: 50.2%;
    margin-top: 4rem;

    @media (min-height: 1060px) {
      margin-top: 8rem;
    }
  }
}