@import '../../style/_global.scss';

#site-map {

  fieldset {
    margin-bottom: 1rem !important;
    padding: 0 0.5rem;
    border: 1px solid $blue !important;
  }

  legend {
    position: relative;
    background: #fff;
    top: -0.15rem;
    padding: 1px 10px !important;
    float:none;
    width:auto;
  }

  .left-side {
    position: relative;
    // padding: 0 5rem 0 1rem;
    z-index: 1;

    @media (min-height: 1000px) {
      padding: 0 5rem 0 1rem;
    }
  }

  .btn {
    width: 100%;
    max-width: 370px;
    padding: 0.5rem 1rem 0.75rem;
    border-radius: 28px;
  }

  .filter-block {

    .btn {
      display: flex;
      align-items: center;
      background: $lightGray;
      color: $blue;
      border: 0;
      text-align: left;

      &::after {
        margin-left: auto;
      }

    }

    .dropdown-menu {
      background-color: $lightGray;
      border: 0;
    }

    .dropdown-item {
      font-size: 1.25rem;
      color: $blue;

      &:hover {
        background: $blue;
        color: #fff;
      }
    }
  }

  .section-title {
    @include sofiaBold;
    font-size: 1.5rem;

    &.laurel {
      width: 90px;
    }
  }

  .section-subtitle {
    @include sofiaSemi;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  .legend-item {
    @include sofiaSemi;
    font-size: 1rem;
    line-height: 1;
  }

  .color-block {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  ul li {
    list-style-type: none;
    line-height: 1.2;
  }

  #map-wrap {
    position: relative;
    // top: -.5rem;
    // right: 7rem;
    aspect-ratio: 1208.47/920;
    // transform: scale(1.19);

    @media (min-height: 1000px) {
      top: -.5rem;
      right: 7rem;
      transform: scale(1.19);
    }

    #shapes {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      g {
        display: block;

        &.hide {
          display: none;
        }

        &.show-elevation .elevation {
          display: block;
        }

        &.mariposa polygon {
          fill: #00a6cf;
          stroke: rgba(0,0,0,0.1);
          stroke-width: 1px;
        }

        &.laurel polygon {
          fill: #f39a33;
          stroke: rgba(0,0,0,0.1);
          stroke-width: 1px;
        }

        text {
          fill: #fff;
          font-size: 0.45rem;
        }

        polygon,
        path
         {
          stroke: rgba(0,0,0,0.3);
          stroke-width: 1px;
        }

        g polygon,
        g path {
          stroke: none;
        }

        // .laurel {
        //   fill: $orange;
        //   stroke: gray;
        //   stroke-width: 1px;
        // }
      }

      text {
        color: #fff;
      }

      .elevation {
        display: none;
        stroke: none;
        fill: #fff !important;
      }

      .st0{fill:#F39A33;}
      .st1{fill:#FFFFFF;}
      .st2{fill:#CB7929;}
      .st3{display:none;}
      .st4{display:inline;fill:none;}
      .st5{fill:#505251;}
      .st6{font-family:'DIN2014-Bold';}
      .st7{font-size:13.177px;}
      .st8{fill:#E51E25;}
      .st9{display:none;fill:#FFFFFF;}
      .st10{display:none;fill:#0086A4;}
      .st11{fill:#00A6CE;}
      .st12{fill:#0086A4;}
      .st13{fill:none;}

      .cls-1 {
        fill: #505251;
      }

      .cls-2 {
        fill: #e51e25;
      }

      .cls-3 {
        fill: #00a6ce;
      }

      .cls-4 {
        fill: #fff;
      }
    }
  }
  
  .secondary-legend {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    background: #fff;
    padding: 1rem;
    line-height: 1;
    border: 1px solid #000;
    z-index: 10;

    @media (min-height: 1000px) {
      right: 5rem;
      bottom: 6rem;
    }

    svg,
    .color-block {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
    }

    .cls-1 {
      fill: #e51e25;
    }

    .cls-2 {
      fill: #fff;
    }

    .cls-3 {
      fill: #505251;
    }

    .cls-1, .cls-2, .cls-3 {
      stroke-width: 0px;
    }
  }
}