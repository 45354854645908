@import '../../style/global';

#menu {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $blue;
  width: 100%;
  height: $menu-height-ipad;
  text-align: center;
  z-index: 10;

  @include bp(1900) {
    height: $menu-height;
  }

  @media print {
    display: none;
  }

  ul {
    flex: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
    li {
      display: flex;
      position: relative;
      list-style: none;
      height: 100%;

      &.has-sub-menu {

        &:hover {

          .sub-menu {
            opacity: 1;
            z-index: 2;
            transition: opacity 0.25s z-index 0.25s;
          }
        }
      }

      &.logo {
        
        img {
          height: 68%;
        }
      }
      
      &.br-logo {
        flex: none;
        width: 100px;
        margin-right: 1rem;

        @include bp(1200) {
          width: 120px;
        }

        @include bp(1900) {
          width: 188px;
          margin-right: 2rem;
        }

        a {
          padding: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1rem;
        @include sofiaLight;
        font-size: 1rem;
        color: #fff;
        text-decoration: none;
        z-index: 1;

        @include bp(768) {
          font-size: 1.25rem;
        }

        @include bp(1200) {
          font-size: 1.5rem;
        }
        
        @include bp(1900) {
          padding: 0 2rem;
          font-size: 2rem;
        }
        &.active {
          @include sofiaBold;
        }

        span {
          margin-top: -0.5rem;
        }

        img {
          display: block;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .sub-menu {
    opacity: 0;
    z-index: -1;
    transition: opacity 0.25s, z-index 0.25s;
    position: absolute;
    bottom: 73%;
    left: 2rem;
    background: $lightGray;
    width: 100%;
    height: 90%;
    padding: 0.5rem 0;
    border-radius: 10px;

    @media (min-height: 1000px) {
      padding: 0.75rem 0;
    }

    li {
      justify-content: center;
      width: 100%;
    }

    a {
      display: flex;
      justify-content: center;
      height: auto;
      width: 100%;
      // padding: 0.25rem 0;
      font-size: 1.25rem;
      line-height: 1;
      color: $blue;
      text-align: center;

      &:hover {
        background: $blue;
        color: #fff;
      }

      span {
        margin-top: -0.25rem;
      }
    }
  }

  .disclaimer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.5rem;
    cursor: pointer;

    @include bp(1900) {
      font-size: 14px;
    }
  
    .hud {
      width: 14px;
      height: 14px;
      margin: 0;
    }
  }
}
