@import '../../style/_global.scss';

#hood {
  position: relative;

  #hood-title-bar {
    padding-right: 3rem;
    padding-left: 3rem;

    @media (max-height: 1060px) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    &.laurel {
      background: $orange;
    }

    &.mariposa {
      background: $medBlue;
    }

    .logo-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 246px;
      height: 90px;
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 3px solid #fff;

      @media (min-height: 1060px) {
        width: auto;
        height: 109px;
        margin-right: 3rem;
        padding-right: 3rem;
        padding-left: 1rem;
      }
    }

    .hood-logo {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .active-plan {

    .title {
      padding: 0 0 0.25rem;
      @include sofiaBold;
      font-size: 2rem;
      line-height: 1;
      color: #fff;
      text-transform: uppercase;
      border: 0;
      border-bottom: 2px solid transparent;
    }
  }

  .number {
    font-size: 0.75em;
  }

  .specs-plan-wrap {
    position: relative;
  }

  .plan-nav {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translateY(-50%);

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-flex;
      flex-direction: column;
      margin-left: 1rem;

      @include bp(1900) {
        margin-left: 2rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      opacity: 0.65;
      background: none;
      padding: 0 0 0.25rem;
      @include sofiaBold;
      font-size: 1.25rem;
      line-height: 1;
      color: #fff;
      text-transform: uppercase;
      border: 0;
      border-bottom: 2px solid transparent;

      @include bp(1449) {
        font-size: 1.5rem;
      }

      // @include bp(1900) {
      //   font-size: 2rem;
      // }

      &.active {
        opacity: 1;
        border-color: #fff;
      }
    }
  }

  .plan-specs {
    color: #fff;
  }
}