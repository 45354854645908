@import "~bootstrap/scss/bootstrap";
@import './typography.scss';
@import './_colors.scss';
@import './animations';

@mixin bp($breakpoint) {
  @media only screen and (min-width: $breakpoint + px) {
    @content;
  }
}

$menu-height-ipad: 80px;
$menu-height: 100px;
$submenu-height: 56px;
$sidebar-width-ipad: 20.5%;
$sidebar-width: 16.35%;
$map-width-ipad: 658px;
$map-width: 956px;

body {
  margin: 0;
  padding: 0;
  /* Improves font rendering, fixes "chunky" fonts */
  -webkit-font-smoothing: antialiased;
  @include sofia;
  font-size: 18px;
  line-height: 1.4;
  color: $text;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  overflow: hidden;
}

#app {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
}

.container {
  max-width: 100%;
}

.main-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.internal-wrap {
  position: relative;
  height: calc(100% - #{$menu-height-ipad});
  max-height: calc(100% - #{$menu-height-ipad});

  @include bp(1900) {
    height: calc(100% - #{$menu-height});
    max-height: calc(100% - #{$menu-height});
  }
}

.img-responsive {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
}

.btn {
  align-self: center;
  background: $blue;
  width: auto;
  padding: 0.75rem 1rem 1.1rem;
  font-size: 1.25rem;
  line-height: 1;
  color: #fff;
  border-radius: 10px;

  &:hover {
    background: $blue;
    color: #fff;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.modal.show .modal-dialog {
  // min-height: 0;
  // top: 50%;
  // transform: translateY(-50%);
}

.modal-close {
  display: block;
  position: absolute;
  top: -2rem;
  right: 0;
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border: 0;

  @include bp(1440) {
    right: -2rem;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #fff;
  }
}

.carousel {

  .slider-list {
    display: flex;
    align-items: center;
  }

  .slider-slide {
    width: 100% !important;
  }

  .slider-control-centerleft {
    left: 2rem !important;
    transform: translateY(-50%) rotate(180deg) !important;
  }

  .slider-control-bottomcenter {
    bottom: -2rem !important;
  }

  .slider-control-centerright {
    right: 2rem !important;
  }

  .arrow {
    display: block;
    background: transparent;
    width: 23.49px;
    height: 41.45px;
    padding: 0;
    border: 0;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }
}