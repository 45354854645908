@mixin sofiaLight {
  font-family: "sofia-pro", sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin sofia {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin sofiaSemi {
  font-family: sofia-pro,sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin sofiaBold {
  font-family: sofia-pro,sans-serif;
  font-weight: 800;
  font-style: normal;
}